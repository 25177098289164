



























































































import { Component, Vue } from 'vue-property-decorator'
import { submitNullfiy } from '@/pages/videoGame/api'
import { callAppFunc_goHome } from '@/common/utils/bridge'
@Component({ name: 'nullify' })
export default class extends Vue {
  showAlert = false
  isAgree = false
  showPopover = false
  actions = [{ name: '我再想想' }, { name: '确认注销' }]
  /**
   * 注销账号下一步按钮点击
   */
  get nextBtnEvent() {
    return {
      pageId: this.$route.meta?.pageId,
      eventId: 'CancelAccountNextBtn',
      eventType: 'liveType',
      targetType: 'liveButton'
    }
  }
  get nullfilyBtnEvent() {
    return {
      pageId: this.$route.meta?.pageId,
      eventId: 'CancelAccountConfirmBtn',
      eventType: 'liveType',
      targetType: 'liveButton'
    }
  }
  get cancelBtnEvent() {
    return {
      pageId: this.$route.meta?.pageId,
      eventId: 'CancelAccountthinkAboutItBtn',
      eventType: 'liveType',
      targetType: 'liveButton'
    }
  }
  hanldNullify() {
    if (this.isAgree === false) {
      this.showPopover = true
      return
    } else {
      this.showAlert = true
    }
  }
  loading = false
  async commitNullify() {
    if (this.loading) return
    this.loading = true
    try {
      await submitNullfiy()
      /**返回app首页，安卓端通过IM消息自动回到app首页 */
      callAppFunc_goHome()
    } finally {
      this.loading = false
    }

    //todo
    //this.$toast('注销成功')
  }
  handlSelect(action: any, index: number) {
    if (index === 1) {
      this.commitNullify()
    }
  }
}
